import React from 'react'
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Form,
  Table,
  Alert,
  Badge,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComment,
  faExclamationCircle,
  faInfoCircle,
  faCaretRight,
  faVial,
} from '@fortawesome/free-solid-svg-icons'

import UIForm from '../../../../UI/FormReload'
import UIFormInputResult from '../../../../UI/FormReload/Input/Result'
import UIFormInputFileImage from '../../../../UI/FormReload/Input/File/Image'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UIButton from '../../../../UI/Button'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIModal from '../../../../UI/Modal'
import ReSample from './update_result_resampling'

// Hooks
import {
  useHistory,
  useQuery,
  useMutation,
  useState,
  useRef,
  useEffect,
  useGlobal,
} from '../../../../../hooks'

// Queries
import { UPDATE_RESULTS, ESSAY_SAMPLE, GET_RESULTS_BY_SAMPLE } from './queries'

const SampleFormResults = ({ id }) => {
  const [{ me }] = useGlobal()

  const history = useHistory()

  const [changed, setChanged] = useState(false)

  const essays_values = useRef()

  const [modalTrigger, setModalTrigger] = useState([])

  // --------------------------------------------------------------------------
  //
  // Query: Results
  //
  // --------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_RESULTS_BY_SAMPLE, {
    fetchPolicy: 'network-only',
    variables: { id, noise: me.id_laboratory },
    onErrorMessage: `No se pudo cargar los resultados de la muestra`,
  })

  // --------------------------------------------------------------------------
  //
  // Mutation: Update results
  //
  // --------------------------------------------------------------------------

  const [t5UpdateResults, { loading: loadingUpdateResults }] = useMutation(
    UPDATE_RESULTS,
    {
      onSuccessMessage:
        'Los resultados de la muestra fueron actualizados con éxito',
      onErrorMessage: `No se pudo actualizar los resultados`,
      onSuccess: () => setChanged(false),
    },
  )

  // --------------------------------------------------------------------------
  //
  // Mutation: Essay sample
  //
  // --------------------------------------------------------------------------

  const [
    t5EssaySample,
    { loading: loadingEssaySample, called: calledEssaySample },
  ] = useMutation(ESSAY_SAMPLE, {
    onSuccessMessage: 'La muesta ha sido ensayada con éxito',
    onErrorMessage: `La muestra no pudo ser ensayada`,
  })

  useEffect(() => {
    if (
      data &&
      data.t5ResultsBySample &&
      data.t5ResultsBySample.data &&
      data.t5ResultsBySample.data.essays
    ) {
      essays_values.current = data.t5ResultsBySample.data.essays
        .filter(({ info: { id } }) => [1, 2, 3, 4, 5].includes(id))
        .reduce(
          (p, { info: { id, name }, data }) => ({
            ...p,
            [id]: {
              name,
              data: data.reduce(
                (p, { id_essay, name, value }) => ({
                  ...p,
                  [id_essay]: { name, value },
                }),
                {},
              ),
            },
          }),
          {},
        )
    }
  }, [data])

  // --------------------------------------------------------------------------
  //
  // Render
  //
  // --------------------------------------------------------------------------

  // Loading
  if (loading) return <UILoading />

  // Data
  if (data && data.t5ResultsBySample && data.t5ResultsBySample.data) {
    let { info, essays, tests } = data.t5ResultsBySample.data

    const handleBack = () => {
      history.push(`/tribology/samples/results`)
    }

    const handleEssaySample = () => {
      // Check for empty values
      const empty_essays = Object.values(essays_values.current).reduce(
        (p, { name, data }) => {
          const empty_data = Object.values(data).reduce(
            (p, { name, value }) => {
              if ([undefined, null, ''].includes(value)) return [...p, name]
              else return p
            },
            [],
          )

          if (empty_data.length) return [...p, { name, data: empty_data }]
          else return p
        },
        [],
      )

      if (empty_essays.length) setModalTrigger(empty_essays)
      else
        t5EssaySample({
          variables: { id },
        })
    }

    const handleAcceptModal = () => {
      t5EssaySample({
        variables: { id },
      })
    }

    const handleUpdateResults = (values) => {
      // Update the results
      t5UpdateResults({
        variables: {
          id,
          results: { ...values },
        },
      })
    }

    // ------------------------------------------------------------------------
    //
    // Render
    //
    // ------------------------------------------------------------------------

    // 1 | Ingresada
    // 2 | Recepcionada
    // 3 | Ensayada
    // 4 | Propuesta
    // 5 | Rechazada
    // 6 | Anulada

    const isDisabled = [1, 4, 5, 6].includes(info.current.stage.id)
    const isEssayed = info.current.stage.id === 3

    return (
      <>
        {(isDisabled || isEssayed) && (
          <Alert
            variant={isDisabled ? 'danger' : 'info'}
            className="text-center"
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> El
            estado actual de la muestra es:{' '}
            <strong>{info.current.stage.name}</strong>
          </Alert>
        )}
        <UIForm onSubmit={handleUpdateResults}>
          <Form.Group as={Row} className="my-4">
            <Col className="d-flex">
              <UIButton
                onClick={handleBack}
                className="mr-auto"
                disabled={loadingUpdateResults || loadingEssaySample}
              >
                Volver a la lista
              </UIButton>
              <UIButton
                type="submit"
                className="mx-2"
                disabled={
                  isDisabled ||
                  !changed ||
                  calledEssaySample ||
                  loadingUpdateResults ||
                  loadingEssaySample
                }
              >
                Actualizar resultados
              </UIButton>
              <UIButton
                onClick={handleEssaySample}
                variant={
                  isEssayed || changed || calledEssaySample
                    ? 'outline-danger'
                    : 'danger'
                }
                disabled={
                  isEssayed ||
                  isDisabled ||
                  changed ||
                  calledEssaySample ||
                  loadingUpdateResults ||
                  loadingEssaySample
                }
              >
                Ensayar muestra
              </UIButton>
            </Col>
          </Form.Group>
          <UIFormSeparation />
          <Table
            responsive
            borderless
            className="text-center font-weight-light"
            size="sm"
          >
            <tbody>
              {/******* fecha muestreo *******/}
              <tr>
                <td
                  className="text-left align-middle my-0 py-0"
                  colSpan={
                    [
                      'antofagasta',
                      'collahuasi',
                      'quebradablanca',
                      'esmax',
                    ].includes(me.laboratory)
                      ? 6
                      : 5
                  }
                >
                  <small>Fecha muestreo</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.previous.date_sampling}</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.current.date_sampling}</small>
                </td>
              </tr>

              {/******* fecha recepción *******/}
              <tr>
                <td
                  className="text-left align-middle my-0 py-0"
                  colSpan={
                    [
                      'antofagasta',
                      'collahuasi',
                      'quebradablanca',
                      'esmax',
                    ].includes(me.laboratory)
                      ? 6
                      : 5
                  }
                >
                  <small>Fecha recepción</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.previous.date_receive}</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.current.date_receive}</small>
                </td>
              </tr>

              {/******* H/K Equipo *******/}
              <tr>
                <td
                  className="text-left align-middle my-0 py-0"
                  colSpan={
                    [
                      'antofagasta',
                      'collahuasi',
                      'quebradablanca',
                      'esmax',
                    ].includes(me.laboratory)
                      ? 6
                      : 5
                  }
                >
                  <small>Horas / Km Equipo</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.previous.machine_continuity}</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.current.machine_continuity}</small>
                </td>
              </tr>

              {/******* H/K componente *******/}
              <tr>
                <td
                  className="text-left align-middle my-0 py-0"
                  colSpan={
                    [
                      'antofagasta',
                      'collahuasi',
                      'quebradablanca',
                      'esmax',
                    ].includes(me.laboratory)
                      ? 6
                      : 5
                  }
                >
                  <small>Horas / Km Componente</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.previous.component_continuity}</small>
                </td>
                <td className="border-left border-right my-0 py-0">
                  <small>{info.current.component_continuity}</small>
                </td>
              </tr>

              {/******* H/K Lubricante *******/}
              <tr>
                <td
                  className="text-left align-middle mt-0 pt-0"
                  colSpan={
                    [
                      'antofagasta',
                      'collahuasi',
                      'quebradablanca',
                      'esmax',
                    ].includes(me.laboratory)
                      ? 6
                      : 5
                  }
                >
                  <small>Horas / Km Lubricante</small>
                </td>
                <td className="border-left border-right mt-0 pt-0">
                  <small>{info.previous.lubricant_continuity}</small>
                </td>
                <td className="border-left border-right mt-0 pt-0">
                  <small>{info.current.lubricant_continuity}</small>
                </td>
              </tr>

              {/******* First row *******/}
              <tr>
                {[
                  'antofagasta',
                  'collahuasi',
                  'quebradablanca',
                  'esmax',
                ].includes(me.laboratory) ? (
                  <td className="bg-stng border text-white align-middle">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Repetición de ensayo
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faVial} />
                    </OverlayTrigger>
                  </td>
                ) : (
                  ''
                )}
                <td className="bg-stng border text-white align-middle">
                  <small>Ensayo</small>
                </td>
                <td
                  className="bg-stng border text-white align-middle"
                  colSpan={4}
                >
                  <small>Límites</small>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        <div className="text-left py-3 px-5">
                          <Badge
                            className="mx-1"
                            style={{ minWidth: '50px' }}
                            variant="danger"
                          >
                            LIC
                          </Badge>
                          {' < '}
                          <Badge
                            className="mx-1"
                            style={{ minWidth: '50px' }}
                            variant="warning"
                          >
                            LIM
                          </Badge>
                          {' < '}
                          <Badge
                            className="mx-1"
                            style={{ minWidth: '50px' }}
                            variant="warning"
                          >
                            LSM
                          </Badge>
                          {' < '}
                          <Badge
                            className="mx-1"
                            style={{ minWidth: '50px' }}
                            variant="danger"
                          >
                            LSC
                          </Badge>
                          <p className="pt-4">Leyenda:</p>
                          <ul className="px-0 mt-0 pt-0">
                            <li>
                              <Badge
                                className="mx-1"
                                style={{ minWidth: '50px' }}
                                variant="danger"
                              >
                                LSC
                              </Badge>
                              : Límite Superior Condenatorio
                            </li>
                            <li>
                              <Badge
                                className="mx-1"
                                style={{ minWidth: '50px' }}
                                variant="warning"
                              >
                                LSM
                              </Badge>
                              : Límite Superior Marginal
                            </li>
                            <li>
                              <Badge
                                className="mx-1"
                                style={{ minWidth: '50px' }}
                                variant="warning"
                              >
                                LIM
                              </Badge>
                              : Límite Inferior Marginal
                            </li>
                            <li>
                              <Badge
                                className="mx-1"
                                style={{ minWidth: '50px' }}
                                variant="danger"
                              >
                                LIC
                              </Badge>
                              : Límite Inferior Condenatorio
                            </li>
                            <li>
                              <Badge
                                className="mx-1"
                                style={{ minWidth: '50px' }}
                                variant="light"
                              >
                                --
                              </Badge>
                              : Límite no especificado
                            </li>
                          </ul>
                        </div>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </td>
                <td className="bg-stng-lighter border text-stng-darker align-middle">
                  <small className="text-stng-dark">
                    {info.previous.relative_id ? (
                      <>
                        (Correlativo#{info.previous.relative_id})
                        <br />
                        <strong>{info.previous.id}</strong>
                      </>
                    ) : (
                      ''
                    )}
                    {info.previous.comment && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {info.previous.comment}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faComment} className="ml-2" />
                      </OverlayTrigger>
                    )}
                  </small>
                </td>
                <td className="bg-stng-lighter border text-stng-darker align-middle">
                  <small className="text-stng-dark">
                    {`(Correlativo#${info.current.relative_id})`}
                    <br />
                    <strong>{info.current.id}</strong>
                    {info.current.comment && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {info.current.comment}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faComment} className="ml-2" />
                      </OverlayTrigger>
                    )}
                  </small>
                </td>
              </tr>
              {/* Map for each classification ********************************************************/}
              {essays.map(({ info, data }, index_classification) => {
                const {
                  id: id_essay_classification,
                  name: name_essay_classification,
                } = info

                return (
                  <React.Fragment
                    key={`${index_classification}-classifications`}
                  >
                    <tr className="text-left">
                      <td colSpan={7}>
                        <UIFormSeparation text={name_essay_classification} />
                      </td>
                    </tr>
                    {/* Map for each essay ***********************************************************/}
                    {data.map(
                      (
                        {
                          is_resampled,
                          id_essay,
                          name,
                          value,
                          value_prev,
                          lsm,
                          lsc,
                          lim,
                          lic,
                        },
                        index_result,
                      ) => {
                        let baseClassName = [
                          'text-center',
                          'align-middle',
                          'm-0',
                          'p-0',
                        ]

                        // Get style
                        // -----------------------------------
                        const getStyle = (value) => {
                          if (value && !isNaN(value)) {
                            value = parseFloat(value)

                            const hasLsc = lsc && lsc !== '' && value > lsc
                            const hasLsm = lsm && lsm !== '' && value > lsm
                            const hasLim = lim && lim !== '' && value < lim
                            const hasLic = lic && lic !== '' && value < lic

                            return hasLsc || hasLic
                              ? {
                                  backgroundColor: '#dc3545',
                                  color: 'white',
                                }
                              : hasLsm || hasLim
                              ? {
                                  backgroundColor: '#ffc107',
                                  color: 'white',
                                }
                              : index_result % 2 === 0
                              ? {
                                  backgroundColor: 'rgba(0,0,0,0.05)',
                                }
                              : {}
                          }

                          return index_result % 2 === 0
                            ? {
                                backgroundColor: 'rgba(0,0,0,0.05)',
                              }
                            : {}
                        }

                        // Get style limit
                        // -----------------------------------
                        const limitStyle = {
                          ...(index_result % 2 === 0
                            ? { backgroundColor: 'rgba(0,0,0,0.15)' }
                            : { backgroundColor: 'rgba(0,0,0,0.05)' }),
                          borderRight: '1px dashed grey',
                        }

                        // Limit badge
                        // -----------------------------------
                        const LimitBadge = ({ variant, value }) =>
                          value ? (
                            <Badge
                              className="mx-0"
                              style={{ minWidth: '42px' }}
                              variant={variant}
                            >
                              {value}
                            </Badge>
                          ) : (
                            <Badge
                              className="mx-0"
                              style={{ minWidth: '42px' }}
                              variant="light"
                            >
                              --
                            </Badge>
                          )

                        // Prev value
                        // -----------------------------------
                        const PreviousValue = ({ value }) => {
                          if (value && !isNaN(value)) {
                            value = parseFloat(value)

                            const hasLsc = lsc && lsc !== '' && value > lsc
                            const hasLsm = lsm && lsm !== '' && value > lsm
                            const hasLim = lim && lim !== '' && value < lim
                            const hasLic = lic && lic !== '' && value < lic

                            return hasLsc || hasLic ? (
                              <Badge
                                className="mx-1 px-3 py-2"
                                style={{ minWidth: '50px' }}
                                variant="danger"
                              >
                                <strong>{value}</strong>
                              </Badge>
                            ) : hasLsm || hasLim ? (
                              <Badge
                                className="mx-1 px-3 py-2"
                                style={{ minWidth: '50px' }}
                                variant="warning"
                              >
                                <strong>{value}</strong>
                              </Badge>
                            ) : (
                              <small>{value}</small>
                            )
                          }

                          return <small>{value}</small>
                        }

                        const isInteger = (value) =>
                          !isNaN(value) &&
                          parseInt(Number(value)) == value &&
                          !isNaN(parseInt(value, 10))

                        const validator = (value) => {
                          if (
                            [null, undefined, '', 'NSD', 'NA'].includes(value)
                          )
                            return

                          if (me.laboratory === 'centinela') {
                            if (
                              [
                                38, // Color
                                37, // Contaminación
                                36, // Apariencia
                              ].includes(id_essay)
                            )
                              return

                            if (
                              [
                                43, // Nitritos
                              ].includes(id_essay)
                            ) {
                              if (!/^[<>]?[-+]?[0-9]*(\.[0-9]+)?$/.test(value))
                                return 'El valor debe ser un entero'
                              else return
                            }
                          }
                          if (me.laboratory === 'quebradablanca') {
                            if (
                              [
                                59, // Color
                                60, // Contaminación
                                61, // Claridad
                                62, // Tipo
                              ].includes(id_essay)
                            )
                              return

                            if (
                              [
                                58, // Nitritos
                              ].includes(id_essay)
                            ) {
                              if (!/^[<>]?[-+]?[0-9]*(\.[0-9]+)?$/.test(value))
                                return 'El valor debe ser un entero'
                              else return
                            }
                          }

                          const essay_iso = {
                            antofagasta: 47,
                            collahuasi: 47,
                            quebradablanca: 47,
                            centinela: 55,
                            esmax: 47,
                            capstonecopper: 47,
                          }

                          if (essay_iso[me.laboratory] === id_essay) {
                            if (!/^([<>]?[0-9]+\/){2}[<>]?[0-9]+$/.test(value))
                              return 'El valor debe tener formato ISO'
                          } else {
                            if (!/^([-+]?[0-9]*(\.[0-9]+)?)?$/.test(value))
                              return 'El valor debe ser un número'
                          }

                          const essay_pq = {
                            antofagasta: 29,
                            collahuasi: 29,
                            centinela: 29,
                          }

                          if (essay_pq[me.laboratory] === id_essay) {
                            if (!isInteger(value))
                              return 'El valor debe ser un entero'

                            if (
                              ['antofagasta'].includes(me.laboratory) &&
                              (parseInt(value) % 5 !== 0 || parseInt(value) < 0)
                            )
                              return 'Debe ser múltiplo de 5'
                          }

                          if ([31, 32, 33].includes(id_essay)) {
                            if (parseFloat(value) < 0.01)
                              return 'No puede ser menor a 0.01'
                          }
                        }

                        // Render
                        // -----------------------------------
                        return (
                          <tr
                            key={`essays-${index_classification}-${index_result}`}
                          >
                            {[
                              'antofagasta',
                              'collahuasi',
                              'quebradablanca',
                              'esmax',
                            ].includes(me.laboratory) ? (
                              <td
                                style={{
                                  ...getStyle(),
                                  borderRight: '1px dashed grey',
                                }}
                                className={baseClassName}
                              >
                                <small>
                                  <ReSample
                                    idSample={id}
                                    idEssay={id_essay}
                                    isResampled={is_resampled}
                                    value={value}
                                  />
                                </small>
                              </td>
                            ) : (
                              ''
                            )}
                            <td
                              style={{
                                ...getStyle(),
                                borderRight: '1px dashed grey',
                              }}
                              className={baseClassName}
                            >
                              <small>{name}</small>
                            </td>
                            <td style={limitStyle} className={baseClassName}>
                              <LimitBadge variant="danger" value={lic} />
                            </td>
                            <td style={limitStyle} className={baseClassName}>
                              <LimitBadge variant="warning" value={lim} />
                            </td>
                            <td style={limitStyle} className={baseClassName}>
                              <LimitBadge variant="warning" value={lsm} />
                            </td>
                            <td style={limitStyle} className={baseClassName}>
                              <LimitBadge variant="danger" value={lsc} />
                            </td>
                            <td style={getStyle()} className={baseClassName}>
                              <PreviousValue value={value_prev} />
                            </td>
                            <td
                              style={{ ...getStyle(), maxWidth: '200px' }}
                              className={baseClassName}
                            >
                              <UIFormInputResult
                                name={id_essay}
                                initialValue={value || ''}
                                placeholder={value}
                                disabled={isDisabled}
                                style={getStyle(value)}
                                onChange={(event) => {
                                  essays_values.current[
                                    id_essay_classification
                                  ].data[id_essay].value = event.target.value
                                  setChanged(true)
                                }}
                                validator={validator}
                              />
                            </td>
                          </tr>
                        )
                      },
                    )}
                  </React.Fragment>
                )
              })}
              {[tests.patchTest, tests.magneticPlug, tests.ferrography].map(
                (test) => {
                  if (!test) return null

                  const { name, value, comment, id_essay, id_protocol } = test

                  return (
                    <>
                      <tr className="text-left">
                        <td colSpan={7}>
                          <UIFormSeparation text={name} />
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={7}
                          style={{ backgroundColor: 'rgba(0,0,0,0.05)' }}
                          className="text-center align-middle border-left border-right m-0 p-0"
                        >
                          <UIFormInputFileImage
                            name={id_essay}
                            initialValue={value}
                            disabled={isDisabled}
                            bucketKey="results/images"
                            onDelete={() => setChanged(true)}
                            onChange={() => setChanged(true)}
                          />
                          <UIFormInputTextarea
                            name={`comment_${id_essay}`}
                            initialValue={comment || ''}
                            placeholder={comment}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    </>
                  )
                },
              )}
            </tbody>
          </Table>
        </UIForm>
        <UIModal
          trigger={modalTrigger}
          onAccept={handleAcceptModal}
          title={'Confirmar ensayos vacíos'}
          body={
            <>
              <p>Los siguientes ensayos no tienen resultados:</p>
              <dl>
                {modalTrigger.map(({ name, data }) => (
                  <>
                    <dt className="my-2">{name}</dt>
                    {data.map((name) => (
                      <dd>
                        <FontAwesomeIcon icon={faCaretRight} className="mr-2" />{' '}
                        <small>{name}</small>
                      </dd>
                    ))}
                  </>
                ))}
              </dl>
            </>
          }
          acceptText="Ensayar"
          acceptVariant="danger"
        />
      </>
    )
  }

  // Error
  return <UIError />
}

export default SampleFormResults
